import { useEffect } from "react"

const Login = () => {
  useEffect(() => {
    //delete session cookie
    document.cookie = "dashsess=''; expires= Thu, 21 Aug 2014 20:00:00 UTC"
    window.top.location.href = "/dashadm/cas"
  })
  return null
}

export default Login
